import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Margin from '../components/Margin'
import MaxWidth from '../components/MaxWidth'
import SEO from '../components/seo'
import ImageTitle from '../components/ImageTitle'
import LargeLink from '../components/LargeLink'

interface SnProps {
  data: GatsbyTypes.SnQuery
}

const SnPage: React.FC<SnProps> = ({
  data: { titleBackground, pageContent },
}) => (
  <Layout>
    <SEO title="Studienämnden" />
    {titleBackground && titleBackground.childImageSharp && (
      <ImageTitle
        title="Studienämnden"
        image={titleBackground.childImageSharp.fluid}
      />
    )}
    <Margin>
      <MaxWidth>
        <h3>{pageContent?.frontmatter?.title}</h3>
        {pageContent && pageContent.html && (
          <div
            className="markdown"
            dangerouslySetInnerHTML={{ __html: pageContent.html }}
          />
        )}
        <br />
        <LargeLink
          key={'guide'}
          link={
            'https://drive.google.com/file/d/1UDBovD4eDjZiQYJKugf8I4_5vriMfimA/view?usp=sharing'
          }
          title={'Studienämndens guide till matte-galaxen'}
        />
      </MaxWidth>
    </Margin>
  </Layout>
)

export const query = graphql`
  query Sn {
    titleBackground: file(relativePath: { eq: "photos/kth.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pageContent: markdownRemark(
      frontmatter: { slug: { eq: "/studienamnden" } }
    ) {
      html
      frontmatter {
        title
      }
    }
  }
`

export default SnPage
